import fetch from 'auth/FetchInterceptor';

const AuthService = {};

AuthService.login = async function (data) {
  const response = await fetch({
    url: '/auth/login',
    method: 'post',
    data: data
  });
  if (response.data && response.data.token) {
    localStorage.setItem('jwtToken', response.data.token.token);
  }
  console.log(response);
  return response;
};

AuthService.register = async function (data) {
  const response = await fetch({
    url: '/auth/register',
    method: 'post',
    data: data
  });
  if (response.data && response.data.token) {
    localStorage.setItem('jwtToken', response.data.token.token);
    localStorage.setItem('signUp', 'AUUZ##EFT');
  }
  return response;
};

AuthService.logout = function () {
  localStorage.removeItem('jwtToken');
  return fetch({
    url: '/auth/logout',
    method: 'post'
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: '/auth/loginInOAuth',
    method: 'post'
  });
};

export default AuthService;
